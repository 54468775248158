<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
          </div>
          <div class="footer-version">Version 1.6.3 - Altismedia 2022</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    const {
      atraiter, esttraites, encours, resolus, all, tickets,
    } = toRefs(props)
    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
